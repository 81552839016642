export const tableOption = {
  menuAlign: 'left',
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  expand: false,
  selection: true,
  column: [
    {
      label: '供应商名称',
      prop: 'supplierName',
    },
    {
      prop: 'contactName',
      label: '联系人'
    },
    {
      prop: 'phone',
      label: '联系方式'
    },
    {
      prop: 'email',
      label: '邮箱'
    },
    {
      label: '时间',
      prop: 'createTime'
    }
  ]
}
